export const CREATE_BID_REQUEST = "CREATE_BID_REQUEST";
export const CREATE_BID_SUCCESS = "CREATE_BID_SUCCESS";
export const CREATE_BID_FAIL = "CREATE_BID_FAIL";
export const CREATE_BID_RESET = "CREATE_BID_RESET";

export const MY_BID_REQUEST = "MY_BID_REQUEST";
export const MY_BID_SUCCESS = "MY_BID_SUCCESS";
export const MY_BID_FAIL = "MY_BID_FAIL";

export const ALL_BIDS_REQUEST = "ALL_BIDS_REQUEST";
export const ALL_BIDS_SUCCESS = "ALL_BIDS_SUCCESS";
export const ALL_BIDS_FAIL = "ALL_BIDS_FAIL";

export const UPDATE_BID_REQUEST = "UPDATE_BID_REQUEST";
export const UPDATE_BID_SUCCESS = "UPDATE_BID_SUCCESS";
export const UPDATE_BID_FAIL = "UPDATE_BID_FAIL";
export const UPDATE_BID_RESET = "UPDATE_BID_RESET";

export const DELETE_BID_REQUEST = "DELETE_BID_REQUEST";
export const DELETE_BID_SUCCESS = "DELETE_BID_SUCCESS";
export const DELETE_BID_FAIL = "DELETE_BID_FAIL";
export const DELETE_BID_RESET = "DELETE_BID_RESET";

export const BID_DETAILS_REQUEST = "BID_DETAILS_REQUEST";
export const BID_DETAILS_SUCCESS = "BID_DETAILS_SUCCESS";
export const BID_DETAILS_FAIL = "BID_DETAILS_FAIL";

export const ADD_TO_BIDITEMS = "ADD_TO_BIDITEMS"; 

export const CLEAR_ERRORS = "CLEAR_ERRORS";  