export const ALL_PROJECT_REQUEST = "ALL_PROJECT_REQUEST";
export const ALL_PROJECT_SUCCESS = "ALL_PROJECT_SUCCESS";
export const ALL_PROJECT_FAIL = "ALL_PROJECT_FAIL";

export const ADMIN_PROJECT_REQUEST = "ADMIN_PROJECT_REQUEST";
export const ADMIN_PROJECT_SUCCESS = "ADMIN_PROJECT_SUCCESS";
export const ADMIN_PROJECT_FAIL = "ADMIN_PROJECT_FAIL";

export const NEW_PROJECT_REQUEST = "NEW_PROJECT_REQUEST";
export const NEW_PROJECT_SUCCESS = "NEW_PROJECT_SUCCESS";
export const NEW_PROJECT_RESET = "NEW_PROJECT_RESET";
export const NEW_PROJECT_FAIL = "NEW_PROJECT_FAIL";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECT_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECT_SUCCESS";
export const UPDATE_PROJECT_RESET = "UPDATE_PROJECT_RESET";
export const UPDATE_PROJECT_FAIL = "UPDATE_PROJECT_FAIL";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECT_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_RESET = "DELETE_PROJECT_RESET";
export const DELETE_PROJECT_FAIL = "DELETE_PROJECT_FAIL";

export const PROJECT_DETAILS_REQUEST = "PROJECT_DETAILS_REQUEST";
export const PROJECT_DETAILS_SUCCESS = "PROJECT_DETAILS_SUCCESS";
export const PROJECT_DETAILS_FAIL = "PROJECT_DETAILS_FAIL";

export const NEW_REVIEW_REQUEST = "NEW_REVIEW_REQUEST";
export const NEW_REVIEW_SUCCESS = "NEW_REVIEW_SUCCESS";
export const NEW_REVIEW_RESET = "NEW_REVIEW_RESET";
export const NEW_REVIEW_FAIL = "NEW_REVIEW_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";   